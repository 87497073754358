<template lang="html">
    <section class="opinion container">
        <section class="container">
            <template v-if="opinions">
                <section class="container-grid">
                    <div class="grid-big">
                        <template v-for="(opinion, key, index) in opinions" :key="key">
                            <opinionFeatured v-if="index == 0" :dataContent="opinion"></opinionFeatured>
                        </template>
                    </div>
                    <div class="grid-small">
                        <template v-for="(opinion, key, index) in opinions" :key="key">
                            <opinionSmall v-if="index == 1" :dataContent="opinion"></opinionSmall>
                        </template>
                    </div>
                </section>
                <section class="grid-3">
                    <template v-for="(opinion, key, index) in opinions" :key="key">
                        <opinionSmall v-if="index > 1" :dataContent="opinion"></opinionSmall>
                    </template>
                </section>
                <paginator :pageActual="page" :totalItems="OpinionsMaxItems" @changed="changePages" @clicked="changePages"></paginator>
            </template>
            <div v-else class="container-noResult">
                <p class="title-dest-underline">No hay resultados</p>
            </div>
        </section>
    </section>
</template>

<script lang="js">
import {
    mapGetters,
    mapActions,
    mapMutations
} from 'vuex';
import store from '@/store';
import opinionFeatured from "@/components/opinions/opinion-featured.vue";
import opinionSmall from "@/components/opinions/view-opinion-small.vue";
import paginator from "@/components/paginator.vue";
import utils from "@/utils/utils.js";
export default {
    name: 'opinion',
    components: {
        opinionFeatured,
        opinionSmall,
        paginator
    },
    props: ['filterTime'],
    computed: {
        ...mapGetters({
            getPagesOpinion: 'contents/getPagesOpinion',
            loading: 'contents/getLoading',
            page: 'contents/getOpinionViewPage',
            theme: 'contents/getOpinionLastFiltersTheme',
            zone: 'contents/getOpinionLastFiltersZone'
        }),
        opinions: function () {
            return store.getters['contents/getOpinions'](this.page)
        },
        OpinionsMaxItems() {
            return this.getPagesOpinion();
        }
    },
    methods: {
        ...mapActions({
            loadOpinions: 'contents/loadOpinions'
        }),
        ...mapMutations({
            setPageOpinion: 'contents/setPageOpinion',

        }),
        changePages(item) {
            this.setPageOpinion(item)
            this.loadOpinions({
                page: this.page,
            });
            utils.LoaderScrollToTop();
        },
    },
    mounted() {
        this.loadOpinions();
    },
    watch: {
        filterTime: function (newVal, oldVal) {
            this.loadOpinions();
        }
    }
}
</script>

<style scoped>
</style>