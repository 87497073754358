<template lang="html">
  <section class="opinion-featured" v-if="dataContent">
    <router-link
      :to="{
        name: 'page-entry',
        params: {
          typeContent: this.dataContent.type,
          slug: this.dataContent.slug,
          typeMedia: this.typeMedia,
        },
      }"
      class="content-new"
      title="Enlace a opinión"
    >
      <h2>
        <Markdown
          v-if="this.dataContent.name_markdown"
          class="title-h1"
          :source="this.dataContent.name_markdown"
        />
        <span class="date-text">{{
          currentDateTime(dataContent.publish_date)
        }}</span>
        <span class="author" v-if="dataContent.writer_name">
          {{ dataContent.writer_name }}</span
        >
        <template v-for="theme in dataContent.themes" :key="theme.id">
          <span class="category-tag">{{ theme.name }}</span>
        </template>
        <span class="category-location" v-if="dataContent.zone">{{
          dataContent.zone.name
        }}</span>
      </h2>

      <div class="info-box">
        <img
          :src="dataContent.image.url"
          alt="Imagen opinión"
          class="img-round"
        />

        <div class="box-content">
          <p class="subtitle">
            {{ dataContent.featured_header }}
          </p>
          <p>
            {{ dataContent.header }}
          </p>
        </div>
      </div>
    </router-link>
  </section>
</template>

<script lang="js">
import moment from 'moment'
import Markdown from 'vue3-markdown-it';
export default {
    name: 'opinion-featured',
    props: ['dataContent'],
    components: {
        Markdown
    },
    mounted() {

    },
    data() {
        return {

        }
    },
    methods: {
        currentDateTime(date) {
            return moment(date).locale('es').format('L')
        },
    },
    computed: {
        typeContent() {
            switch (this.dataContent.type) {
                case 'entry':
                    return 'noticia'
                case 'opinion':
                    return 'opinion'
                case 'interview':
                    return 'entrevista'
                case 'coverage':
                    return 'reportaje'
                case 'event':
                    return 'evento'
                case 'media':
                    return 'multimedia'
                default:
                    return 'noticia'
            }
        }
    }
}
</script>

<style scoped lang="scss">
@import "@/styles/colours.scss";

.opinion-featured {
  margin-bottom: 20px;
  cursor: pointer;

  .content-new {
    text-decoration: none;
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    /* max-height: 300px; */
    position: relative;
    border-radius: 13px;
    padding-left: 10px;
    background: $blueLight;
    padding: 30px;

    .title-h1 {
      width: 100%;
      margin-bottom: 8px;
    }

    .category-location {
      margin-left: 15px;
    }

    .info-box {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      align-items: center;
      width: 100%;
      padding: 20px 0px;
      text-align: center;

      .img-round {
        width: 180px;
        height: 180px;
        border-radius: 50%;
        object-fit: cover;
        object-position: center center;
        margin-bottom: 15px;
      }

      .box-content {
        width: calc(100% - 180px);
        text-align: left;
        padding-left: 25px;
      }
    }
  }
}

@media screen and (max-width: 750px) {
  .opinion-featured .content-new {
    padding: 20px;
  }

  .opinion-featured .content-new .info-box {
    flex-direction: column;
    padding: 0;
  }

  .opinion-featured .content-new .info-box .box-content {
    width: 100%;
    text-align: left;
    padding-left: 0;
  }
}
</style>
